@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,700');

/**
 * Style variables
 */

$base-font-family: 'Source Code Pro',
monospace !default;
$base-font-size: 18.5px !default;
$mobile-font-size: 18px !default;
$code-font-size: 15px !default;
$base-line-height: 1.5 !default;
$container-width: 90% !default;
$container-max-width: 800px !default;
$grid-gap: 1rem;

/**
 * Global
 */

body {
    background-color: var(--background-color);
    margin: 0 auto;
    padding: 0;
    font-family: $base-font-family;
    font-size: $base-font-size;
    color: var(--text-color);
    text-align: justify;
    line-height: $base-line-height !important;
}

@media print,
screen and (max-width: 768px) {
    body {
        font-size: 90%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: $base-font-size;
    margin: 22px 0 15px;
    padding-bottom: 15px;
    font-weight: bold;
    color: var(--text-color);
    text-align: left;
}

h1 {
    font-size: 225%
}

h2 {
    font-size: 150%
}

h3 {
    font-size: 100%
}

.right-text {
  text-align: right;
}

h2::after {
    content: "";
    bottom: 2px;
    position: relative;
    margin-bottom: -0.5em;
    display: block;
    width: 100%;
    border-bottom: 3px dotted var(--accent);
    border-top: 3px dotted var(--accent);
    height: 2px;
}

p,
ul,
ol {
    margin: 0px 0px 15px 0px;
    color: var(--text-color);
}

img {
    max-width:100%;
    margin-top: 0.2em;
}

a {
    text-decoration: none;
    color: var(--link-color);
}

a:hover {
    color: var(--background-color);
    background-color: var(--base-color);
}

a.ctf {
    background-color: transparent;
}

.primary-text {
    color: var(--primary-color);
}

@media only screen and (max-device-width: 500px) {
    * {
        font-size: $mobile-font-size !important;
    }
}


/*h1:before { content: "#";  margin-right: 9px; }
h2:before { content: "##";  margin-right: 9px; }
h3:before { content: "###";  margin-right: 9px; }
h4:before { content: "####";  margin-right: 9px; }
h5:before { content: "#####";  margin-right: 9px; }
h6:before { content: "######";  margin-right: 9px; }*/


/**
 * Layout
 */

.container {
    width: $container-width;
    max-width: $container-max-width;
    margin-right: auto;
    margin-left: auto;
}

p {
    word-wrap: break-word;
    word-break: break-word;
}

footer {
    color: var(--text-color);
    border-top: var(--border);
    margin: 20px auto 15px;
    padding-top: 10px;
    text-align: right;
}

header {
    margin-top: 25px;
    margin-bottom: 10px;
}

header p {
    text-align: left;
    margin: 0;
}

footer {
    margin-bottom: 20px;
}

div.half {
    width: 50%;
    max-width: 400px;
    float: left;
}

div.onethird {
    width: 25%;
    max-width: 200px;
    float: left;
}

div.twothirds {
    width: 100%;
    max-width: 600px;
    float: left;
}

img.logo {
    width: 100%
}

.emph {
    color: var(--accent)
}

blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    border-left: 3px solid var(--accent);
}

blockquote p {
    margin-left: 1em
}

table {
    font-size: 85%;
    width: 100%;
    max-width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-collapse: collapse;
}

th,
td {
    border-top: 1px solid rgba(12,227,172,0.3);
    border-bottom: 1px solid rgba(12,227,172,0.3);
    text-align: left;
}

th {
    font-weight: bold;
}

.grid-ctfs-custom {
    display: grid;
    .grid-item-ctf {
        padding-left: $grid-gap;
        padding-right: $grid-gap;
        padding-top: $grid-gap / 2;
        padding-bottom: $grid-gap / 2;
        // &>* {
        //     width: 100%;
        //     height: 100%;
        // }
    }
}

@media print,
screen and (max-width: 600px) {
    .grid-ctfs-custom {
        grid-template-columns: 1fr;
    }
}

@media print,
screen and (min-width: 600px) {
    .grid-ctfs-custom {
        grid-template-columns: repeat(2, 1fr);
    }
}

.posts-list {
    width: 100%;
    .writeups-item {
        display: list-item;
    }
}

.posts-table {
    .td {
    text-align: left;
    }
}


/**
 * Highlight/Markup
 */

::selection {
    background: var(--selection-background);
    color: var(--selection-text);
}

::-moz-selection {
    background: var(--selection-background);
    color: var(--selection-text);
}


/**
 * Lists
 */

:not(.menu)>ul {
    list-style: none;
    padding-left: 0;
}

:not(.menu)>ul {
    list-style-type: none;
}

:not(.menu)>ul>li:before {
    content: "-";
    margin-right: 9px;
}


/**
 * Header/Navigation
 */

.menu {
    border-top: var(--border);
    border-bottom: var(--border);
    margin-bottom: 25px;
}

.menu ul {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 0px;
    list-style-type: none;
    text-align: right;
}

.menu ul li {
    display: inline;
    margin-left: 10px;
}

.menu ul li a {
    text-decoration: none;
    color: var(--text-color);
}

.menu ul li a:hover {
    text-decoration: none;
    color: var(--background-color);
    background-color: var(--accent);
}

.grid-header-custom {
    display: grid;
    .grid-item-header {
        padding-left: $grid-gap / 2;
        padding-right: $grid-gap / 2;
        padding-top: $grid-gap / 2;
        padding-bottom: $grid-gap / 2;
        text-align: right;
        // &>* {
        //     width: 100%;
        //     height: 100%;
        // }
    }
}

@media print,
screen and (max-width: 500px) {
    .grid-header-custom {
        grid-template-columns: 1fr;
    }
}

@media print,
screen and (min-width: 500px) {
    .grid-header-custom {
        grid-template-columns: 200px 1fr;
    }
}

@media print,
screen and (min-width: 800px) {
    .grid-header-custom {
        grid-template-columns: repeat(1, minmax(200px 25%) 1fr);
    }
}

.auth {
    border-bottom: 3px dotted var(--accent);
    margin-bottom: 0.3em;
}

#loading {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

#calendar {
    max-width: 85%;
    margin: 0 auto;
}

@media print,
screen and (max-width: 768px) {
    #calendar {
        width: 100%;
        margin: 0 auto;
        font-size: 75%;
    }
}


/**
 * Form
 */

input,
select,
textarea {
    padding: 0;
    margin: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border: none;
}

input[type=text],
select,
textarea {
    width: 100%;
    resize: none;
    background-color: var(--background-color);
    color: var(--text-color);
    caret-color: var(--text-color);
    font-size: $base-font-size;
    font-family: $base-font-family;
    line-height: $base-line-height;
}

input,
select,
textarea,
textarea::-webkit-input-placeholder {
    text-indent: 0px;
}

::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--placeholder-color);
}

::-ms-input-placeholder {
    color: var(--placeholder-color);
}

input[type=submit] {
    font-size: $base-font-size;
    font-family: $base-font-family;
    line-height: $base-line-height;
    cursor: pointer;
    color: var(--link-color);
    background-color: var(--background-color);
}

input[type=submit]:hover {
    color: var(--background-color);
    background-color: var(--base-color);
}

*:focus {
    outline: none;
}

textarea {
    vertical-align: top;
}


/**
  * Image Gallery
  */

.image-gallery {
    overflow: auto;
    margin-left: -1%!important;
}

.image-gallery a {
    float: left;
    display: block;
    margin: 1% 1% 1% 1%;
    width: 30%;
    text-align: center;
    text-decoration: none!important;
}

.image-gallery a span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 3px 0;
}

.image-gallery a img {
    width: 100%;
    display: block;
}


/**
 * Code and syntax highlighting
 */

code.language-plaintext {
  color: var(--code-color-inline);
}

.lineno {
    color: var(--code-color-1);
    margin-right: 15px;
}

figure.highlight {
    margin: 5px 0;
}

pre {
    background-color: #222222;
    padding: 10px;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-all;
    font-size: $code-font-size;
    color: var(--text-color);
    line-height: 1.7 !important;
    font-family: $base-font-family !important;
    border: 1px solid #cccccc;
}

.highlight .hll {
    background-color: var(--code-color-2);
}

.highlight .c {
    color: var(--code-color-1);
    font-style: italic
}


/* Comment */

.highlight .err {
    color: var(--code-color-3);
    background-color: var(--code-color-4);
}


/* Error */

.highlight .k {
    color: var(--code-color-5);
}


/* Keyword */

.highlight .cm {
    color: var(--code-color-1);
    font-style: italic
}


/* Comment.Multiline */

.highlight .cp {
    color: var(--code-color-6);
}


/* Comment.Preproc */

.highlight .c1 {
    color: var(--code-color-1);
    font-style: italic
}


/* Comment.Single */

.highlight .cs {
    color: var(--code-color-5);
    font-style: italic
}


/* Comment.Special */

.highlight .gd {
    color: var(--code-color-7);
}


/* Generic.Deleted */

.highlight .ge {
    font-style: italic
}


/* Generic.Emph */

.highlight .gr {
    color: var(--code-color-7);
}


/* Generic.Error */

.highlight .gh {
    color: var(--code-color-8);
    font-weight: bold
}


/* Generic.Heading */

.highlight .gi {
    color: var(--code-color-9);
}


/* Generic.Inserted */

.highlight .go {
    color: var(--code-color-10);
}


/* Generic.Output */

.highlight .gp {
    color: var(--code-color-11);
}


/* Generic.Prompt */

.highlight .gs {
    font-weight: bold
}


/* Generic.Strong */

.highlight .gu {
    color: var(--code-color-12);
    font-weight: bold
}


/* Generic.Subheading */

.highlight .gt {
    color: var(--code-color-7);
}


/* Generic.Traceback */

.highlight .kc {
    color: var(--code-color-5);
}


/* Keyword.Constant */

.highlight .kd {
    color: var(--code-color-5);
}


/* Keyword.Declaration */

.highlight .kn {
    color: var(--code-color-5);
}


/* Keyword.Namespace */

.highlight .kp {
    color: var(--code-color-5);
}


/* Keyword.Pseudo */

.highlight .kr {
    color: var(--code-color-5);
}


/* Keyword.Reserved */

.highlight .kt {
    color: var(--code-color-13);
}


/* Keyword.Type */

.highlight .m {
    color: var(--code-color-14);
}


/* Literal.Number */

.highlight .s {
    color: var(--code-color-15);
}


/* Literal.String */

.highlight .na {
    color: var(--code-color-16);
}


/* Name.Attribute */

.highlight .nb {
    color: var(--code-color-13);
}


/* Name.Builtin */

.highlight .nc {
    color: var(--code-color-9);
    text-decoration: underline
}


/* Name.Class */

.highlight .no {
    color: var(--code-color-7);
}


/* Name.Constant */

.highlight .nd {
    color: var(--code-color-10);
}


/* Name.Decorator */

.highlight .ni {
    color: var(--code-color-17);
    font-weight: bold
}


/* Name.Entity */

.highlight .nf {
    color: var(--code-color-9);
}


/* Name.Function */

.highlight .nn {
    color: var(--code-color-13);
    text-decoration: underline
}


/* Name.Namespace */

.highlight .nt {
    color: var(--code-color-16);
    font-weight: bold
}


/* Name.Tag */

.highlight .nv {
    color: var(--code-color-7);
}


/* Name.Variable */

.highlight .ow {
    color: var(--code-color-5);
}


/* Operator.Word */

.highlight .w {
    color: var(--code-color-18);
}


/* Text.Whitespace */

.highlight .mf {
    color: var(--code-color-14);
}


/* Literal.Number.Float */

.highlight .mh {
    color: var(--code-color-14);
}


/* Literal.Number.Hex */

.highlight .mi {
    color: var(--code-color-14);
}


/* Literal.Number.Integer */

.highlight .mo {
    color: var(--code-color-14);
}


/* Literal.Number.Oct */

.highlight .sb {
    color: var(--code-color-15);
}


/* Literal.String.Backtick */

.highlight .sc {
    color: var(--code-color-15);
}


/* Literal.String.Char */

.highlight .sd {
    color: var(--code-color-15);
}


/* Literal.String.Doc */

.highlight .s2 {
    color: var(--code-color-15);
}


/* Literal.String.Double */

.highlight .se {
    color: var(--code-color-15);
}


/* Literal.String.Escape */

.highlight .sh {
    color: var(--code-color-15);
}


/* Literal.String.Heredoc */

.highlight .si {
    color: var(--code-color-15);
}


/* Literal.String.Interpol */

.highlight .sx {
    color: var(--code-color-15);
}


/* Literal.String.Other */

.highlight .sr {
    color: var(--code-color-14);
}


/* Literal.String.Regex */

.highlight .s1 {
    color: var(--code-color-15);
}


/* Literal.String.Single */

.highlight .ss {
    color: var(--code-color-5);
}


/* Literal.String.Symbol */

.highlight .bp {
    color: var(--code-color-13);
}


/* Name.Builtin.Pseudo */

.highlight .vc {
    color: var(--code-color-7);
}


/* Name.Variable.Class */

.highlight .vg {
    color: var(--code-color-7);
}


/* Name.Variable.Global */

.highlight .vi {
    color: var(--code-color-7);
}


/* Name.Variable.Instance */

.highlight .il {
    color: var(--code-color-14);
}


/* Literal.Number.Integer.Long */
