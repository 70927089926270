/**
 * Dark theme variables
 */

:root {
    --base-color: #DBDBDB;
    --primary-color: #A2FC8F;
    --border: 3px dotted var(--accent);
    --selection-background: rgba(219, 219, 219, 0.99);
    --selection-text: #000;
    --background-color: #101010;
    --text-color: var(--base-color);
    --placeholder-color: var(--base-color);
    --link-color: #0ce3ac;
    --accent: #0ce3ac;
    --code-color-inline: #ffffcc;
    --code-color-1: #aaaaaa;
    --code-color-2: #ffffcc;
    --code-color-3: #F00000;
    --code-color-4: #F0A0A0;
    --code-color-5: #b38aff;
    --code-color-6: #5ba711;
    --code-color-7: #e4e477;
    --code-color-8: #000080;
    --code-color-9: #05ca05;
    --code-color-10: #888888;
    --code-color-11: #555555;
    --code-color-12: #800080;
    --code-color-13: #00d4d4;
    --code-color-14: #00c1c1;
    --code-color-15: #ed9d13;
    --code-color-16: #1e90ff;
    --code-color-17: #800000;
    --code-color-18: #bbbbbb;
}
